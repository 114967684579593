import type { EventWithConfig, LinkedTG, TicketGroupsAndTypesResponse, WithMeta } from '@/api/types/processedEntities'
import { groupBy } from '@/helpers/IndexHelpers'
import { assignGroupColor } from '@/helpers/TicketGroupsColors'

export function toLinkedGroups(response: TicketGroupsAndTypesResponse): LinkedTG[] {
  const r = apiEntities(response)
  return linkTGRelations(r.ticket_group, r.meta, r.ticket_type)
}

export function linkTGRelations(ticketGroups: TicketGroup[], meta: MetaEntity[], types: TicketType[]): LinkedTG[] {
  const withMeta = linkToMetadata('ticket_group', ticketGroups, meta)
  const clonedTypes = types.map((type) => ({ ...type }))
  const result = linkGroupsToTypes(withMeta, clonedTypes) as LinkedTG[]
  linkTypesBackToGroups(result)
  return result.map(assignGroupColor)
}

/**
 * Mutates the passed in array of ticket groups.
 */
function linkTypesBackToGroups(groups: LinkedTG[]): void {
  for (const group of groups) {
    for (const type of group.types) {
      type.group = group
    }
  }
}

function linkGroupsToTypes<G extends TicketGroup, Result extends G & { types: TicketType[] }>(
  groups: G[],
  types: TicketType[],
): Result[] {
  return linkRelatedEntities(groups, types, 'ticket_group_id', 'types') as Result[]
}

// TODO Make generic or support additional entity types.
// interface ConfigData {
//   [namespace: string]: {
//     [name: string]: ConfigEntityValue
//   }
// }
function nestEventConfigEntities(entities: ConfigEntity[]): EventWithConfig['config'] {
  const result = {}

  for (const item of entities) {
    result[item.namespace] = result[item.namespace] ?? {}
    result[item.namespace][item.name] = item.value
  }

  return result
}

type ConfigApiResponse = ApiResponse<'meta' | 'config'>

export function mergeConfigIntoEvent(template: EventTemplate, response: ConfigApiResponse): EventWithConfig {
  const [result] = mergeConfigIntoEvents([template], response)
  return result
}

export function mergeConfigIntoEvents(templates: EventTemplate[], response: ConfigApiResponse): EventWithConfig[] {
  const { config, meta } = apiEntities(response)
  const byEventId = groupBy('resource_id', config)

  return linkToMetadata('event_template', templates, meta).map((template) => {
    const entities = byEventId[template.id]
    const result: EventWithConfig = {
      ...template,
      config: entities ? nestEventConfigEntities(entities) : {},
    }
    return result
  })
}

// TODO Use shared helpers linkMetaTo.
export function linkToMetadata<T extends ApiEntity>(
  entity_type: string,
  primaryEntities: T[],
  metadataEntities: MetaEntity[],
): WithMeta<T>[] {
  const result = {}

  for (const original of primaryEntities) {
    const parent = { ...original } as WithMeta<T>
    parent.meta = {}
    result[parent.id] = parent
  }

  for (const { resource, resource_id, metakey, value } of metadataEntities) {
    if (resource === entity_type) {
      const parent = result[resource_id]
      if (parent) {
        parent.meta[metakey] = value
      }
    }
  }

  return Object.values(result)
}

export function linkRelatedEntities(parents: ApiEntity[], children: ApiEntity[], relation: string, collection: string) {
  const result = {}

  for (const original of parents) {
    const parent = { ...original }
    parent[collection] = []
    result[parent.id] = parent
  }

  for (const child of children) {
    const parentId = child[relation]
    const parent = result[parentId]
    if (parent) {
      parent[collection].push(child)
    }
  }

  return Object.values(result)
}

export function firstEntity<
  Response extends ApiResponse<any>,
  Name extends keyof Response,
  Result extends Response[Name]['_data'][0][],
>(response: Response, resourceName: Name, ...more: Name[]): Result {
  const result: ApiEntity[] = []

  // At least one resource name is required.
  for (const resource of [resourceName, ...more]) {
    result.push(response[resource]._data[0])
  }

  return result as Result
}

export function apiEntities<
  Response extends ApiResponse<any>,
  Result extends { [Key in keyof Response]: Response[Key]['_data'] },
>(response: Response): Result {
  const result: Dict<ApiEntity[]> = {}

  for (const [key, value] of Object.entries(response)) {
    // Ignore meta properties like _limit and _total.
    if (!key.startsWith('_')) {
      result[key as EntityTypeName] = value._data
    }
  }

  return result as Result
}
