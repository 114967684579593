import { authenticationMethod } from '@/helpers/Environment'
import { languageItem } from '@/language/helpers'
import { isLoggedIn } from '@/state/Authentication'
import { loginAndTransferCart } from '@/state/Cart'

interface Field {
  id: string
  key: string
  label: string
  type: string
  maxlength: number
}

interface PartialField extends Partial<Field> {
  label: string
  key: string
}

/**
 * Returns the prefix for API endpoints that require the `my/` prefix when the user is logged in.
 *
 * The Ticketure API (`tix-api`) has no concept of anonymous users; Every API query must have a user.
 * Therefore tix-web uses API credentials for a system user with `app-public` role (blue column) when
 * interacting with tix-api on behalf of guests. tix-web's API end-points that must use authenticated
 * customer/member credentials are prefixed with `/my`.
 *
 * For some endpoints, front-ends must therefore;
 *   - use `my/` when logged in, and
 *   - not use `my` when not logged in.
 *
 * TODO Give authenticated users access to the GET end-points that anonymous users can access.
 *
 * @deprecated Prefer to give the `public` role (pink column) access to the un-prefixed end-point.
 *
 * Only Andrew has access to change API endpoint permissions. Access is controlled via a permissions spreadsheet:
 * @see https://docs.google.com/spreadsheets/d/1jNcdqA6i-BmMwWIMu6r9jOQD7zAC7FiQJNDWOfEgYOs/edit?usp=sharing
 */
export function loggedInPrefix(): string {
  return isLoggedIn() ? 'my/' : ''
}

export function authenticationFields(): [Field, Field] | undefined {
  const t = languageItem('loginForm')

  const emailAddress: PartialField = {
    label: 'Email address',
    key: 'username',
    type: 'email',
    maxlength: 100,
  }

  const password: PartialField = {
    label: 'Password',
    key: 'password',
    type: 'password',
  }

  const membershipNumber: PartialField = {
    label: t.memberID,
    key: 'scan_code',
  }

  const zipCode: PartialField = {
    label: 'ZIP Code',
    key: 'zip_code',
    type: 'tel',
    maxlength: 5,
  }

  const lastName: PartialField = {
    label: t.lastNameLabel,
    key: 'last_name',
  }

  const fields = {
    password: [emailAddress, password],
    scan_code: [membershipNumber, zipCode],
    scan_code_password: [emailAddress, membershipNumber],
    scan_code_last_name: [membershipNumber, lastName],
  }

  function complete(field: PartialField): Field {
    return {
      id: field.key,
      type: 'text',
      maxlength: 50,

      // Allow field spec to override the above defaults.
      ...field,
    }
  }

  if (authenticationMethod && fields[authenticationMethod]) {
    const [a, b] = fields[authenticationMethod]
    return [complete(a), complete(b)]
  }
}

export function automaticLogin(credentials: Dictionary): Promise<void> {
  if (
    // If the tenant is configured to support consumer login…
    authenticationMethod &&
    // and uses a less secure authentication method…
    authenticationMethod.startsWith('scan_code') &&
    // and both credentials are specified…
    fieldNames().every((name) => credentials[name]?.length > 0)
  ) {
    // then attempt login.
    return (
      loginAndTransferCart(credentials)
        // Ignore invalid credentials. They are probably out of date.
        .catch(() => undefined)
    )
  } else {
    return Promise.resolve()
  }
}

function fieldNames(): string[] {
  const [a, b] = authenticationFields()!
  return [a.key, b.key]
}
