
import { Component, Prop, Vue } from 'vue-property-decorator'
import type { LanguageStrings } from '@/language/types'
import { API } from '@/api/API'
import store from '@/store/store'
import { firstEntity } from '@/api/Helpers'
import { TixTime } from '@/TixTime/TixTime'
import { getLogoExternalUrl } from '@/helpers/Environment'
import { isLoggedIn } from '@/state/Authentication'

@Component({ name: 'CancelAutoRenewRoute' })
export default class extends Vue {
  @Prop({ required: true })
  ticketOrderId: string

  initialisationError: string | null = null
  error: string | null = null

  loading = true

  apiResponse: ApiResponse<'ticket_order' | 'ticket' | 'ticket_group' | 'membership' | 'venue'> | null = null

  t: LanguageStrings['cancelAutoRenewRoute']

  created() {
    this.fetchMembershipDetails()
      .catch((err) => {
        this.initialisationError = err.message
      })
      .finally(() => {
        this.loading = false
      })
  }

  cancelAutoRenew() {
    this.error = null
    this.loading = true

    API.delete(`ticket_order/${this.ticketOrderId}/auto_renew`)
      .then(() => {
        if (isLoggedIn()) {
          // Reload membership from API.
          store.dispatch('Member/load')
        }
      })
      .then(this.fetchMembershipDetails)
      .catch((err) => {
        this.error = err.message
      })
      .finally(() => {
        this.loading = false
      })
  }

  goHome() {
    const externalUrl = getLogoExternalUrl()
    if (externalUrl) {
      window.location.href = externalUrl
    } else {
      this.$router.push('/')
    }
  }

  get membershipDetails(): { membership: MembershipEntity; group: TicketGroup; ticket: Ticket; venue: Venue } | null {
    if (this.apiResponse) {
      const [membership, ticket, group, venue] = firstEntity(
        this.apiResponse,
        'membership',
        'ticket',
        'ticket_group',
        'venue',
      )
      return {
        membership,
        ticket,
        group,
        venue,
      }
    } else {
      return null
    }
  }

  get state() {
    return this.membershipDetails?.membership.auto_renew_state
  }

  get cancelled() {
    return this.state === 'cancelled'
  }

  get timezone() {
    return this.membershipDetails?.venue.timezone
  }

  get initialisationWarning() {
    if (this.state !== 'scheduled' && this.state !== 'cancelled') {
      return isLoggedIn() ? this.t.notEnrolledError : this.t.notAuthenticatedError
    } else {
      return null
    }
  }

  get confirmMessage(): string {
    const validTo = new TixTime(this.membershipDetails!.ticket.valid_to, this.timezone)
    const params = {
      level: this.membershipDetails!.group.name,
      valid_to: validTo.format('MMMM D, YYYY'),
    }
    return this.$t('cancelAutoRenewRoute.successful', params) as string
  }

  private async fetchMembershipDetails() {
    return API.getUncached<'ticket_order' | 'ticket' | 'ticket_group' | 'membership' | 'venue'>(
      `ticket_order/${this.ticketOrderId}`,
      {
        embed: 'ticket, ticket_group, membership, event_template, event_template.venue',
        query: {
          'ticket.state._in': 'booked, redeemed',
          'ticket_group.handler': 'membership',
        },
      },
    ).then((res) => {
      this.apiResponse = res
    })
  }
}
