
import NavigateBack from '@/components/elements/NavigateBack.vue'
import FormInput from '@/components/forms/FormInput2.vue'
import { reportFormValidity } from '@/helpers/Validation'
import { apiErrorMessageOrRethrow } from '@/errors/helpers'
import { API } from '@/api/API'
import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * A form with just one email address field and a submit button.
 * Re-used in Forgotten Password, Forgotten Membership and Resend Order.
 */

@Component({
  components: {
    FormInput,
    NavigateBack,
  },
})
export default class extends Vue {
  @Prop() submitText: string
  @Prop() postRoute: string
  @Prop({ default: () => {} }) extraPostParams: Dictionary
  @Prop({ default: false }) treat404AsSuccess: boolean
  @Prop({ default: '' }) custom404Message: string

  email: string | null = null
  submitting: boolean = false
  error: string | void | null = null
  success: boolean = false

  reportSuccess() {
    this.success = true
    this.$emit('success', this.email)
  }

  async submit() {
    this.error = null
    this.success = false
    try {
      await reportFormValidity(this.$refs.form as HTMLFormElement)
      this.submitting = true
      const body = { email: this.email, ...this.extraPostParams }
      await API.post(this.postRoute, { body })
      this.reportSuccess()
    } catch (e) {
      if (e.validationError) {
        // Do nothing.
      } else if (e.response.status === 404 && (this.treat404AsSuccess || this.custom404Message)) {
        if (this.treat404AsSuccess) {
          this.reportSuccess()
        } else {
          this.error = this.custom404Message
        }
      } else {
        this.error = apiErrorMessageOrRethrow(e, 'validate_not_email')
      }
    } finally {
      this.submitting = false
    }
  }
}
