import { parseColorString } from '@/helpers/Color'
import { tenantTeaserImageSize } from '@/helpers/ImageHelpers'
import { camelToKebabCase } from '@/helpers/StringHelpers'
import { ticketGroupColors } from '@/helpers/TicketGroupsColors'
import type { NormalizedThemeOptions, RawThemeConfig, SecondaryButtonStyles, ThemeValue } from '@/themeConfig/types'
import {
  darkGray,
  lightGray,
  mediumGray,
  ticketureBrandThemeConfig,
  unbrandedFallbackThemeConfig,
  veryLightGray,
} from '@/themeConfig/values'
import deepmerge from 'deepmerge'

// This is to fix the TypeScript missing structuredClone function name in the older version of Typescript
// TODO - remove after upgrading Typescript to at least v4.7
declare function structuredClone(value: any, options?: any): any

export function initializeThemeVariables(raw?: RawThemeConfig): NormalizedThemeOptions {
  const options = normalizeThemeOptions(raw)
  const isDark = options.themeMode === 'dark'
  const variables = getThemeVariables(options)
  const root = document.querySelector(':root')!

  // Clear properties
  // This is needed for visual regression testing, when switching between themes we need to clear the previous theme's properties
  root.removeAttribute('style')
  root.classList.remove('dark-theme', 'light-theme')

  // TODO: Document why it has to be the root not body
  const themeClass = isDark ? 'dark-theme' : 'light-theme'
  root.classList.add(themeClass)

  // Insert the CSS Custom Properties <style> at the top of the document <head>
  // so that themes' stylesheets may override them.
  // TODO Use CSS Layers to group styles into precedence layers; reset native elements, layout, components, themes.
  for (const key in variables) {
    document.documentElement.style.setProperty(`--${key}`, variables[key])
  }

  return options
}

export function normalizeThemeOptions(theme?: RawThemeConfig): NormalizedThemeOptions {
  const tier = theme?.tier || 1
  const defaults: Partial<RawThemeConfig> = tier < 3 ? structuredClone(unbrandedFallbackThemeConfig()) : {}

  const result = deepmerge(defaults, theme ?? ticketureBrandThemeConfig()) as NormalizedThemeOptions

  const darken = (color) =>
    parseColorString(color)
      ?.darken(1 / 5)
      .hex()

  const lighten = (color) =>
    parseColorString(color)
      ?.negate()
      .darken(1 / 10)
      .negate()
      .hex()

  const darkPrimary = darken(result.primaryColor)
  const darkSecondary = darken(result.secondaryColor)
  const pageBackgroundColorLighter =
    result.pageBackgroundColorLighter ?? lighten(result.pageBackgroundColor) ?? result.pageBackgroundColor

  const groupColors = {}
  for (const [i, color] of ticketGroupColors.entries()) {
    groupColors[`ticketGroup${i + 1}Color`] = color
  }

  const { width, height } = tenantTeaserImageSize(result.teaserImageSize)

  // Direct raw options
  const options: Partial<NormalizedThemeOptions> = {
    teaserImageWidth: `${width}px`,
    teaserImageHeight: `${height}px`,

    mobileFooterHeight: '80px',
    mobileCartHeaderHeight: '72px',
    mobileCartFooterHeight: '180px',

    // Derived options
    primaryColorDarker: darkPrimary,
    secondaryColorDarker: darkSecondary,
    primaryContrastColor: getContrastTextColor(result.primaryColor),

    // Hard-coded colors
    black: 'black',
    darkGray,
    mediumGray,
    lightGray,
    veryLightGray,

    /* The value must be in milliseconds since it's also used by JS */
    modalTransitionDuration: '200ms',

    pageBackgroundColorLighter,
  }

  return {
    ...options,
    ...groupColors,
    ...result,
    ...getMessageBackgroundColors(result),
    ...getSecondaryButtonStyles(result),
  }
}

function getMessageBackgroundColors(theme: Required<NormalizedThemeOptions>): Dict<string> {
  const result = {}
  const names = ['info', 'success', 'warning', 'error', 'promo']
  names.forEach((name) => {
    const color = theme[`${name}Color`]
    const backgroundColor = theme[`${name}BackgroundColor`]
    if (color && !backgroundColor) {
      result[`${name}BackgroundColor`] = parseColorString(color)?.alpha(0.15).string()
    }
  })
  return result
}

function getSecondaryButtonStyles(
  theme: Required<NormalizedThemeOptions>,
): { secondaryButton: SecondaryButtonStyles } | void {
  const styles = _getSecondaryButtonStyles(theme)
  if (styles) {
    return {
      secondaryButton: styles,
    }
  }
}
function _getSecondaryButtonStyles(theme: Required<NormalizedThemeOptions>): SecondaryButtonStyles | void {
  // Secondary button styles are only used by the fallback theme, used by tier 1 and 2 themes
  if (theme.tier < 3) {
    switch (theme.secondaryButtonStyle) {
      case 'solid':
        return {
          border: `2px solid ${theme.secondaryColor}`,
          background: theme.secondaryColor,
          textColor: getContrastTextColor(theme.secondaryColor),
          borderHover: 'var(--secondary-color-darker)',
          backgroundHover: 'var(--secondary-color-darker)',
          backgroundActive: 'white',
          textColorActive: mediumGray,
        }
      case 'outline':
        return {
          border: [`2px solid ${lightGray}`, `2px solid rgba(255, 255, 255, 0.15)`],
          background: ['white', 'rgba(255, 255, 255, 0.05)'],
          textColor: [mediumGray, 'rgba(255, 255, 255, 0.87)'],
          textColorActive: [mediumGray, 'rgba(255, 255, 255, 0.87)'],
          borderHover: '#bfc1ca',
          backgroundHover: ['white', 'rgba(255, 255, 255, 0.15)'],
          backgroundActive: ['white', 'transparent'],
        }
    }
  }
}

function getThemeVariables(theme: NormalizedThemeOptions): Dictionary {
  const isDarkTheme = theme.themeMode === 'dark'

  function flattenTheme(object: Dict<any>, parentKey?: string): Dict<string> {
    const result: Dict<any> = {}

    for (const [key, value] of Object.entries(object)) {
      const k = parentKey ? `${parentKey}-${camelToKebabCase(key)}` : camelToKebabCase(key)
      const themeValue = getThemeValue(value, isDarkTheme)
      if (themeValue) {
        result[k] = themeValue
      } else {
        Object.assign(result, flattenTheme(value, k))
      }
    }

    return result
  }

  return flattenTheme(theme)
}

export function getThemeValue(value: ThemeValue | undefined, isDarkTheme: boolean): string | undefined {
  if (typeof value === 'string') {
    return value
  } else if (Array.isArray(value)) {
    return isDarkTheme ? value[1] : value[0]
  }
}

function getContrastTextColor(color: string) {
  return parseColorString(color)?.isLight() ? 'black' : 'white'
}
