
import SelectAvailableSession from '@/components/events/SelectAvailableSession.vue'
import { conflictingSessions } from '@/helpers/ConflictHelpers'
import { categoriesThatMayConflict, environment } from '@/helpers/Environment'
import { formatSessionList, hideSoldOutSessions } from '@/helpers/SessionHelpers'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { annotateSession } from '@/helpers/DynamicMessages'
import DynamicMessageGroup from '@/components/events/DynamicMessageGroup.vue'
import type { AnnotatedSession, Session } from '@/types/Sessions'
import type { LanguageStrings } from '@/language/types'
import type { EventDetails, SessionPickerTabsConfig } from '@/api/types/processedEntities'
import TixLegend, { LegendProps } from '@/components/elements/TixLegend.vue'
import { availabilityThreshold } from '@/helpers/AvailabilityHelpers'

/**
 * TODO Use this in <ReserveQuantityFirst>.
 */
@Component({
  name: 'SelectSession',
  components: { TixLegend, DynamicMessageGroup, SelectAvailableSession },
})
export default class extends Vue {
  @Prop({ required: true })
  value: AnnotatedSession | null

  @Prop({ required: true })
  sessions: Session[]

  @Prop({ required: true })
  event: EventDetails

  @Prop()
  quantities: TicketTypeQuantities

  @Prop({ default: false })
  isAllDayEvent: boolean

  @Prop({ default: false })
  showPrices: boolean

  @Prop()
  mockCapacityThreshold?: number | false

  @Prop()
  tabsConfig: SessionPickerTabsConfig

  t: LanguageStrings['selectSession']

  mounted() {
    if (this.annotatedNonConflictingSessions.length === 1) {
      this.$emit('input', this.annotatedNonConflictingSessions[0])
    }
  }

  get annotatedNonConflictingSessions(): AnnotatedSession[] {
    return this.nonConflictingSessions.map((session) => annotateSession(session, this.event, this.quantities))
  }

  get legendProps(): LegendProps {
    return {
      annotations: this.annotatedNonConflictingSessions.map((session) => session.annotations).flat(),
      // Sold-out selectable session buttons are marked clearly with "sold out" on the button itself.
      // The legend should not display anything for sold out sessions.
      areAnySoldOut: false,
      arePricesDisplayed: this.showPrices,
      hasPriceIncrease: this.sessions.some((session) => session.priceIncrease && session.priceIncrease > 0),
    }
  }

  get formattedConflictingSessions(): string {
    return formatSessionList(this.conflictingSessions.filter((session) => !session.sold_out))
  }

  private get nonConflictingSessions(): Session[] {
    if (this.isAllDayEvent) {
      return this.sessions
    } else {
      const conflicting = new Set(this.conflictingSessions.map((session) => session.id))
      return this.sessions.filter((session) => !conflicting.has(session.id))
    }
  }

  get showSoldOutTimes(): boolean {
    return !hideSoldOutSessions(this.event, environment)
  }

  get conflictingSessions(): Session[] {
    // TODO Should this should return [] on all day events? Test handling of all day events that conflict other sessions in the cart.
    if (categoriesThatMayConflict.has(this.event.category)) {
      return []
    } else {
      return conflictingSessions(this.event.id, this.event.meta, this.sessions, this.$store.getters['Cart/cartItems'])
    }
  }

  get title() {
    return this.$t('selectAvailableSession.title')
  }

  get sessionAvailabilities() {
    return this.annotatedNonConflictingSessions
      .filter(session => this.showSoldOutTimes || !session.sold_out)
      .map((session) => session.availableCapacity)
  }

  get threshold() {
    const threshold = this.mockCapacityThreshold ?? environment.web.show_remaining_session_capacity_threshold ?? false
    return availabilityThreshold(this.sessionAvailabilities, threshold)
  }
}
