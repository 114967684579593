import type { StripeError } from '@stripe/stripe-js'

export default class TixStripeError extends Error {
  stripeError = true

  // originalStripeError is used in CheckoutRoute.
  constructor(public originalStripeError?: StripeError) {
    super()
  }
}
