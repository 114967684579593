import { API } from '@/api/API'
import type { GiftCardPayload, PaymentPayload, StripePayload } from '@/api/types/payloads'
import { completedOrdersRequestEmbeds, CompletedOrdersResponse } from '@/checkout/helpers/completing'
import TixStripeError from '@/checkout/stripe/TixStripeError'
import { isLoggedIn } from '@/state/Authentication'
import { Cart } from '@/store/Cart'
import store from '@/store/store'
import type { Stripe, StripeElements } from '@stripe/stripe-js'
import type { ConfirmPaymentData } from '@stripe/stripe-js/types/stripe-js/payment-intents'

export interface CheckoutOptions {
  tokens: PaymentTokens
  // TODO Better name for this scenario?
  injected: boolean
  suppressEmailNotification?: boolean
  purchaser?: string
  giftee?: string
}

export interface PaymentTokens {
  stripe?: string
  captcha?: string
}

interface CompletedCheckoutResponse {
  status: 'completed'
  orders: CompletedOrdersResponse
}

interface PendingCheckoutResponse {
  status: 'pending'
  cart: Cart
}

export type CheckoutResponse = CompletedCheckoutResponse | PendingCheckoutResponse

export async function processPaymentPayloads(
  cart: CartEntity,
  stripe: Stripe,
  payments: PaymentPayload[],
  options: CheckoutOptions,
  elements?: StripeElements,
  confirmPaymentData?: ConfirmPaymentData,
): Promise<CheckoutResponse> {
  // All charges are gift cards, except maybe the last one.
  const last = payments.pop() as PaymentPayload
  const firstN = [...payments] as GiftCardPayload[]
  await processFirstNPayments(cart.id, firstN)
  // Checkout with the last payment.
  return await processCheckout(cart.id, stripe, last, options, elements, confirmPaymentData)
}

async function processFirstNPayments(id: string, payments: GiftCardPayload[]) {
  for (const payment of payments) {
    // Asynchronous!
    await pay(id, payment).then(() => {
      // Remove the gift card if the charge was successful.
      // This prevents it being charged again if credit card fails and
      // the customer needs to try processing checkout again.
      store.commit('Cart/removeGiftCard', payment.gateway_data.ledger_number)
    })
  }
}

function pay(id: string, body: PaymentPayload) {
  return API.post(`my/cart/${id}/pay`, { body })
}

function processCheckout(
  id: string,
  stripe: Stripe,
  payment: PaymentPayload,
  options: CheckoutOptions,
  elements?: StripeElements,
  confirmPaymentData?: ConfirmPaymentData,
): Promise<CheckoutResponse> {
  return checkout(id, payment, options).catch((error) => {
    if (error.response) {
      const { status, data } = error.response
      if (status === 402 && data._data[0]._code === 'intent_requires_action') {
        const clientSecret = data._data[0]._extra.client_secret
        /* eslint-disable no-console */
        console.log('confirm via second factor')
        return stripe
          .confirmPayment({ elements, clientSecret, confirmParams: confirmPaymentData!, redirect: 'if_required' })
          .then((result) => {
            if (result.paymentIntent) {
              const newPayment = { ...payment } as StripePayload
              newPayment.gateway_data.source = result.paymentIntent.id
              return checkout(id, newPayment, options)
            } else {
              throw new TixStripeError(result.error)
            }
          })
      }
    }

    // Rethrow any other errors.
    throw error
  })
}

export function checkout(id: string, payment: PaymentPayload, options: CheckoutOptions) {
  if (options.injected) {
    return checkoutInjectedCart(id, payment, options)
  } else if (isLoggedIn()) {
    return checkoutAuthenticatedCart(id, payment, options.giftee)
  } else {
    return checkoutGuestCart(id, payment, options.purchaser!, options.giftee)
  }
}

function checkoutInjectedCart(id: string, payment: PaymentPayload, options: CheckoutOptions) {
  // No `my/` prefix.
  return post(`cart/${id}/guest_checkout`, payment, { send_email: !options.suppressEmailNotification })
}

function checkoutGuestCart(id: string, payment: PaymentPayload, purchaserId: string, gifteeId?: string) {
  const path = `my/cart/${id}/guest_checkout`
  if (!gifteeId) {
    return post(path, payment, { guest_identity_id: purchaserId })
  } else {
    // A gift recipient is the guest identity.
    return post(path, payment, { guest_identity_id: gifteeId, giver_identity_id: purchaserId })
  }
}

function checkoutAuthenticatedCart(id: string, payment: PaymentPayload, gifteeId?: string) {
  if (!gifteeId) {
    return post(`my/cart/${id}/checkout`, payment)
  } else {
    return post(`my/cart/${id}/gift_checkout`, payment, { giftee_identity_id: gifteeId })
  }
}

function post(
  path: string,
  payment: PaymentPayload,
  identities: CheckoutAPIIdentities = {},
): Promise<CheckoutResponse> {
  const body = { ...payment, ...identities }
  // TODO Optimize: Only fetch the ticket_order, seller, and seller.meta.
  // Pass the cart and purchaser email address from <CheckoutRoute> to <CompletedOrdersRoute>.
  const embed = ['ticket_order', ...completedOrdersRequestEmbeds]
  return API.post(path, { body, embed }).then((response) => {
    // TODO Figure out a way to do better type support for this or have the API return a more expected response.
    // @ts-ignore Response can be `{ _data: { _code: 'intent_processing' }[] }` for BACS checkout.
    if ('_data' in response && response?._data[0]._code === 'intent_processing') {
      return {
        status: 'pending',
        cart: store.getters['Cart/cart'],
      }
    } else {
      return {
        status: 'completed',
        orders: response,
      }
    }
  })
}

type CheckoutAPIIdentities = {} | AnonymousCheckoutIdentities | AuthenticatedGiftedCheckoutIdentities

interface AnonymousCheckoutIdentities {
  guest_identity_id: string
  giver_identity_id?: string
}

interface AuthenticatedGiftedCheckoutIdentities {
  giftee_identity_id: string
}
