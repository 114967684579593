<script>
  import { formatCurrency } from '@/helpers/Currency'
  import { mapGetters } from 'vuex'
  import ApplyGiftCardsModal from '@/modals/ApplyGiftCardsModal.vue'
  import { openModal } from '@/modals/helpers/api'

  export default {
    name: 'ApplyGiftCards',

    props: {
      processing: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      ...mapGetters({
        appliedCards: 'Cart/giftCards',
        paymentDueByCreditCard: 'Cart/paymentDueByCreditCard',
      }),

      addButtonLabel() {
        return this.appliedCards.length > 0 ? this.t.applyAnotherGiftCard : this.t.applyGiftCard
      },
    },

    methods: {
      remove(number) {
        this.$store.commit('Cart/removeGiftCard', number)
      },

      formatCurrency,

      openGiftCardsModal() {
        openModal({
          name: 'gift-cards-modal',
          component: ApplyGiftCardsModal,
          size: 'sm',
          title: this.t.modalTitle,
          props: {
            processing: this.processing,
          },
        })
      },
    },

    watch: {
      processing(processing) {
        this.$modals.$emit('update:modal', {
          name: 'gift-cards-modal',
          props: { processing },
        })
      },
    },
  }
</script>

<template>
  <div class="apply-gift-cards">
    <div v-if="appliedCards.length > 0" class="applied-cards">
      <h4>Gift Card Number</h4>

      <div v-for="card in appliedCards" :key="card.number" class="applied-card">
        <div class="code-wrapper">
          <code>{{ card.number }}</code>
        </div>

        <div class="balance-wrapper">
          <span class="balance-available">{{ formatCurrency(card.balance) }}</span>
        </div>

        <div class="actions">
          <button type="button" @click="remove(card.number)" :disabled="processing" class="remove secondary">
            Remove
          </button>
        </div>
      </div>
    </div>

    <div v-if="paymentDueByCreditCard > 0" class="add-card">
      <button type="button" @click="openGiftCardsModal" :disabled="processing" class="add-card-button">
        <SvgIcon name="PlusIcon" /> {{ addButtonLabel }}
      </button>
    </div>
  </div>
</template>

<style lang="scss">
  .applied-card,
  form.add-card-form .form-container {
    display: flex;
  }

  .applied-cards {
    .applied-card {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .code-wrapper {
        flex-basis: 50%;

        code {
          font-family: inherit;
        }
      }

      .balance-wrapper {
        margin: 0 16px;
      }

      button.remove {
        width: min-content;
        padding: 0;
        line-height: 48px;
      }
    }
  }
</style>
