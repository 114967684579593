
import { API } from '@/api/API'
import { linkToMetadata } from '@/api/Helpers'
import { completedOrdersRequestEmbeds, CompletedOrdersResponse } from '@/checkout/helpers/completing'
import { notFoundRoute } from '@/errors/helpers'
import { environment } from '@/helpers/Environment'
import { imageUrlMaxWidth } from '@/helpers/ImageHelpers'
import { groupBy, indexItemsById } from '@/helpers/IndexHelpers'
import { orderIdsFromQuery } from '@/helpers/QueryStringParameters'
import type { LanguageStrings } from '@/language/types'
import { Component, Vue } from 'vue-property-decorator'
import type { MetaData } from '@/api/types/processedEntities'
import NotFoundError from '@/errors/NotFoundError'
import { setDocumentTitle } from '@/helpers/MiscellaneousHelpers'

@Component({ name: 'CompletedOrdersRoute' })
export default class extends Vue {
  orders: Array<TicketOrder & { ticketGroups: TicketGroup[] }> = []
  emailAddress: string | null = null
  sellerMeta: MetaData = {}

  loading: boolean = true

  $el: HTMLElement

  t: LanguageStrings['completedOrdersRoute']

  get marshaledOrders(): Array<{
    id: string
    label: string
    qrImgSrc?: string
    link: string
    linkLabel?: string
  }> {
    return this.orders
      .map((order) => ({
        id: order.id,
        number: order.order_number,
        handlers: new Set(order.ticketGroups.map((group) => group.handler)),
      }))
      .filter(({ handlers }) => {
        // Ignore orders which only have donations and gift cards.
        handlers.delete('donation')
        handlers.delete('ledger')
        return handlers.size > 0
      })
      .map(({ id, number, handlers }) => {
        const key = `completedOrdersRoute.${this.getOrderNumberLanguage(handlers)}`
        const param = { number: `<code class="order-number">${number}</code>` }

        return {
          id: id,
          label: this.$t(key, param) as string,
          qrImgSrc: `/cached_api/ticket_order/${id}/qrcode?margin=4`,
          link: `/ticket_order/${id}`,
          linkLabel: this.opt.showTicketsLink ? this.t.printOrder : undefined,
        }
      })
  }

  getOrderNumberLanguage(handlers) {
    if (handlers.has('membership')) {
      return 'membershipOrderNumber'
    } else if (handlers.has('codes')) {
      return 'giftOrderNumber'
    } else {
      return 'ticketsOrderNumber'
    }
  }

  get message() {
    const { sellerMeta, portalStrings } = environment

    const template =
      sellerMeta?.completed_order_message ??
      portalStrings.completed_order_message ??
      '<p>We emailed your order confirmation to <em>{{email}}</em>.</p>  <p>Thank you for your purchase.</p>'

    return template.replace('{{email}}', this.emailAddress!)
  }

  get imageUrl() {
    return {
      mobile: imageUrlMaxWidth(this.sellerMeta.completed_mobile_image) || this.opt.completedImage?.mobile,
      desktop: imageUrlMaxWidth(this.sellerMeta.completed_image) || this.opt.completedImage?.desktop,
    }
  }

  get contentOffset() {
    return {
      mobile: this.sellerMeta.completed_content_mobile_offset || this.opt.completedContentOffset?.mobile,
      desktop: this.sellerMeta.completed_content_offset || this.opt.completedContentOffset?.desktop,
    }
  }

  get links(): string | void {
    const { sellerMeta, portalStrings } = environment
    return sellerMeta?.completed_order_links ?? portalStrings.completed_order_links ?? ''
  }

  beforeRouteEnter(to, from, next) {
    setDocumentTitle('Order Confirmation')

    if (to.query.orderIds == null) {
      next(notFoundRoute(to))
    } else {
      next()
    }
  }

  get redirectUrl() {
    return environment.web.external_urls?.completed_order
  }

  created() {
    if (this.redirectUrl) {
      const orderIds = orderIdsFromQuery(this.$route.query.orderIds || []).join(',')
      window.location.href = `${this.redirectUrl}?orderIds=${orderIds}`
    } else {
      this.fetchOrders()
        .then((response) => {
          const tickets = groupBy('ticket_order_id', response.ticket._data)
          const groups = indexItemsById(response.ticket_group._data)
          const sellers = linkToMetadata('seller', response.seller._data, response.meta._data)

          this.orders = response.ticket_order._data.map((order) => ({
            ...order,
            ticketGroups: tickets[order.id].map((ticket) => groups[ticket.ticket_group_id]),
          }))

          this.sellerMeta = sellers[0].meta

          // If the completed page is loaded warm (directly after /checkout), then the purchaser's email address is
          // passed in as a route parameter. For gifted orders, that is not the email address in the api response that
          // checkout passes along in a route parameter.
          this.emailAddress = this.$route.params.purchaserEmailAddress || this.getIdentity(response).email

          // Pass configuration to CSS scope using CSS Custom Properties.
          this.setCSSCustomProperties()

          this.loading = false
        })
        .catch((error) => {
          if (error.response) {
            const { status, data } = error.response
            if (status === 400 && data._data[0]._code === 'bad_field_value') {
              return this.$notFound()
            }
          } else if (error.notFoundError) {
            return this.$notFound()
          }

          throw error
        })
    }
  }

  private fetchOrders(): Promise<CompletedOrdersResponse> {
    const response = this.$route.params.checkoutResponse as CompletedOrdersResponse

    if (response) {
      // Use order data from route params when navigating here from successful checkout.
      // This is an important optimization.
      return Promise.resolve(response)
    } else {
      // Otherwise fetch the order data from the API
      return API.getCached('ticket_order', {
        embed: completedOrdersRequestEmbeds,
        query: {
          'id._in': orderIdsFromQuery(this.$route.query.orderIds),
        },
      }).then((resp) => {
        if (resp.ticket_order._data.length === 0) {
          throw new NotFoundError('ticket_order')
        }
        return resp
      })
    }
  }

  private setCSSCustomProperties() {
    // Wait for the next JavaScript turn so that the <Loader> has gone and the content is in place.
    setTimeout(() => {
      this.setCSSCustomProperty('--completed-offset', this.contentOffset.desktop)
      this.setCSSCustomProperty('--completed-mobile-offset', this.contentOffset.mobile)
    })
  }

  private setCSSCustomProperty(name: string, value?: string) {
    if (value != null) {
      this.$el.style.setProperty(name, value)
    }
  }

  /**
   * Gets identity entity on cold page load.
   *
   * Cold load is when the order complete page has been loaded or reloaded as the entry point into the app.
   */
  private getIdentity(response: CompletedOrdersResponse): Identity {
    const contact = response.contact?._data[0]
    const order = response.ticket_order._data[0]
    // Use the identity of the "giver", if there is one. This means the order was gifted using the older feature
    // for gifting carts and memberships (not the newer "GoM" feature; that uses discount codes.)
    const id = contact?.type === 'Giver' ? contact.identity_id : order.identity_id
    return response.identity._data.find((item) => item.id === id)!
  }
}
