
import BooleanRadios from '@/components/forms/BooleanRadios.vue'
import RadioItems from '@/components/forms/RadioItems.vue'
import { randomIdentifier } from '@/helpers/StringHelpers'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import GiftAidCheckbox from './GiftAddCheckbox.vue'
import type { FormInputType } from '@/components/forms/types'

/**
 * Labelled form inputs, including <select> and radio groups.
 *
 * The :type="" prop is like the <input type=""> attribute, with some additions and exceptions:
 *
 * - 'radio-group': Wraps <RadioGroup>. Unlike built in groups of <input type="radio"> elements,
 *   options can be DE-selected if :required="false".
 * - 'boolean': A <RadioGroup> with a pair of yes/no radio options.
 * - 'select': Uses a <select> instead of an <input>.
 * - options: <RadioGroup> for 5 or fewer options. Otherwise a <select>.
 * - checkbox: uses a single <input> but the label is reordered and inlined.
 * - other values are passed to <input type=""> verbatim.
 *
 * Checkbox groups are also supported via :checkboxArrayValue="". <CancelFreeOrderRoute> route uses one.
 *
 * TODO Add support for :type="checkbox-group"?
 *
 * Validation is primarily done by builtin browser validation using props that translate to standard DOM attributes:
 *
 * - :optional="" is passed on as required="" (negated)
 * - passed on verbatim:
 *   - "disabled"
 *   - "maxlength"
 *   - "minlength"
 *   - "pattern"
 *   - any undocumented attributes.
 *
 * :mustEqual="" and :mustEqualCaseInsensitive="" are special props to trigger validation that compares the value of
 * the input to another field's value via v-same-as="". E.g. email_confirm and password_confirm identity fields.
 *
 * Custom validation can be implemented via the emitted @validation event. E.g. <CitypassCoupons>
 *
 * Custom display of validation error messages can also be implemented using this event. E.g. <TicketTypeStepper>
 *
 * Emitted events:
 *
 * - @input: Emits the value when it changes. Parent components usually listen to this via v-model="".
 * - @validation: Emits the error string and inner <input> or <select> element when an input element has been
 *   validated. Usually this is triggered on blur or on submit of the containing <form> with reportFormValidity().
 *
 * Internally it listens to:
 *
 * - @blur: Builtin event for when an element loses the browser's focus.
 * - @invalid: Dispatched by reportFormValidity() on <input> and <select> elements that are [now] invalid. It mimics
 *             the built-in 'invalid' event.
 *             @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/invalid_event
 * - @validate: Dispatched by reportFormValidity() on <input> and <select> elements that are [now] valid.
 *
 * But parent components should not listen to these. @validate and @invalid should not even be reaching parent
 * components. As of May 2021 however, we were unable to prevent them reaching parent components during the 'capture'
 * phase, even though Vue documentation clearly states that only events bound with the .capture modifier are triggered
 * in the capture phase.
 * @see https://vuejs.org/v2/guide/events.html#Event-Modifiers
 *
 * MDN explains capturing vs bubbling well.
 * @see https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Building_blocks/Events#bubbling_and_capturing_explained
 */
@Component({
  name: 'FormInput',
  components: { BooleanRadios, RadioItems, GiftAidCheckbox },
  // Prefer declared props over inherited attributes.
  // @see https://vuejs.org/v2/guide/components-props.html#Non-Prop-Attributes
  inheritAttrs: false,
})
export default class extends Vue {
  @Prop() label: string | undefined
  @Prop() labelHtml: string | undefined
  @Prop({ default: '' }) value: Primitive
  @Prop({ default: 'text' }) type: FormInputType
  @Prop() options: SelectOption[] | null
  @Prop() enum: string[] | null
  @Prop() placeholder: string | null
  @Prop() description: string | null
  @Prop() error: string | null
  @Prop() id: string | null
  @Prop() required: boolean | null
  @Prop() disabled: boolean | null
  @Prop() autofocus: boolean | null
  @Prop() autocomplete: string | null
  @Prop() mustEqual: string | null
  @Prop() mustEqualCaseInsensitive: string | null

  // Used when binding checkbox inputs to an array
  // https://vuejs.org/v2/guide/forms.html#Checkbox
  // TODO Implement via :type="checkbox-group" and :options="" instead?
  @Prop() checkboxArrayValue: string

  errorInternal: string | null = null

  uniqueId: string = 'form-id-' + randomIdentifier()

  created() {
    this.errorInternal = this.error || null
  }

  get normalizedType(): 'gift-aid-checkbox' | Exclude<FormInputType, 'options'> {
    if (this.type === 'options') {
      // Use radio buttons for up to 3 items.
      return this.selectOptions!.length < 4 ? 'radio-group' : 'select'
    } else if (this.id === 'gift_aid_eligible' && this.type === 'checkbox') {
      return 'gift-aid-checkbox'
    }
    return this.type
  }

  get selectOptions(): SelectOption[] {
    if (this.enum) {
      return this.enum.map((value) => ({ value, label: value }))
    } else {
      return this.options ?? []
    }
  }

  get input() {
    return this.value
  }

  set input(value) {
    this.$emit('input', value)
  }

  get eventListeners() {
    return {
      ...this.$listeners,
      // Maintain compatibility with v-model.
      // @see https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components
      input: () => null,
    }
  }

  get attrs() {
    return {
      name: this.id,
      id: this.id,
      required: this.required,
      disabled: this.disabled,
      placeholder: this.placeholder,
      ...this.$attrs,
    }
  }

  validate() {
    // Get the HTML input within the component.
    // $event.target and refs are not useful because some children are wrappers for multiple inputs, like <RadioGroup>.
    const input = this.inputEl
    const error = input.validity.valid || this.disabled ? null : input.validationMessage
    this.errorInternal = error
    this.$emit('validation', error, input)
  }

  get inputEl() {
    return this.$el.querySelector('input, select') as HTMLSelectElement | HTMLInputElement
  }

  @Watch('error')
  errorPropChanged(value: string | null) {
    this.inputEl.setCustomValidity(value ?? '')
    this.errorInternal = value
  }

  @Watch('disabled')
  disabledPropChanged() {
    this.validate()
  }

  get htmlLabelWithOptional() {
    if (!this.disabled && !this.required) {
      return `${this.labelHtml} <small class="optional"> (${this.t.optional})</small>`
    } else {
      return this.labelHtml
    }
  }

  get invalidInput() {
    return Boolean(this.errorInternal)
  }
}
